<template>
  <div class="fish">
    <div class="fish-warp">
      <div class="fish-main">
         <loading :active.sync="isLoading"
                  :background-color	= "'#000'"
                  :color = "'#fff'"
                  :is-full-page="true"></loading>
        <img class="abs blue-fish animate__animated animate__shakeY animate__duration-15s animate-infinite" src="//txwl.internetbaidu.com/mobileTXW_3.0/fish/blue-fish.png" alt="">
        <img class="abs hot-fish animate__animated animate__shakeY animate__duration-15s animate-infinite" src="//txwl.internetbaidu.com/mobileTXW_3.0/fish/hot-fish.png" alt="">
        <img class="abs fish1 animate__animated animate__shakeY animate__duration-15s animate-infinite" src="//txwl.internetbaidu.com/mobileTXW_3.0/fish/fish1.png" alt="">
        <img class="abs fish2 animate__animated animate__shakeY animate__duration-15s animate-infinite" src="//txwl.internetbaidu.com/mobileTXW_3.0/fish/fish2.png" alt="">
        <ul class="main-game">
          <li v-for="(list, key) in fishList" :key="key" class="animate__animated animate__pulse animate__slow animate-infinite">
            <img :src="list.img" alt="">
            <span @click="jump(list.jump)"></span>
          </li>
        </ul>
        <!-- <img class="abs btn" src="//txwl.internetbaidu.com/mobileTXW_3.0/fish/process.png" alt=""> -->
      </div>
      <footer-copy :pos = "'absolute'"></footer-copy>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "fish",
  data() {
    return {
      isLoading: false,
      fishList: [
        { img: '//txwl.internetbaidu.com/mobileTXW_3.0/fish/fish_con_500.png', jump: 'https://ng.mqbsx.com/GameHandle?gameid=500' },
        { img: '//txwl.internetbaidu.com/mobileTXW_3.0/fish/fish_con_dt510.png', jump: 'https://ng.mqbsx.com/GameHandle?gameid=510' },
        { img: '//txwl.internetbaidu.com/mobileTXW_3.0/fish/fish_con_520.png', jump: 'https://ng.mqbsx.com/GameHandle?gameid=520' },
        { img: '//txwl.internetbaidu.com/mobileTXW_3.0/fish/fish_con_530.png', jump: 'https://ng.mqbsx.com/GameHandle?gameid=530' }
      ]
    };
  },
  methods: {
    jump(url) {
      this.isLoading = true;
      axios.get(url).then(res => {
        this.isLoading = false;
        if (res.status === 200) {
          console.log(res.data.data.url)
          window.location.href = res.data.data.url;
        } else {
          console.log('跳转地址获取失败')
        }
      }).catch(err => {
        this.isLoading = false;
        console.log(err)
      })
    }
  }
};
</script>
<style lang="less" scoped>
.fish {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  .fish-warp {
    height: 100%;
    background: url("//txwl.internetbaidu.com/mobileTXW_3.0/fish/bg.jpg") no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    position: relative;
    .fish-main {
      width: 100%;
      height: 100%;
      position: relative;
      .animate-infinite {
        animation-iteration-count: infinite;
      }
      img.img-main {
        width: 100%;
        height: auto;
      }
      img.abs {
        position: absolute;
        height: auto;
        &.btn {
          width: 512px;
          bottom: -12%;
          left: 50%;
          transform: translateX(-50%);
        }
        &.blue-fish {
          width: 134px;
          top: 4%;
          left: 12%;
        }
        &.hot-fish {
          width: 224px;
          top: 12%;
          right: -2%;
        }
        &.fish1 {
          width: 410px;
          left: -10%;
          bottom: 20%;
        }
        &.fish2 {
          width: 348px;
          right: -4%;
          bottom: 14%;
        }
      }
      .main-game {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;
        top: 10%;
        li {
          width: 46%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;
          img {
            width: 80%;
            height: auto;
          }
          span {
            width: 265px;
            height: 87px;
            background: url('//txwl.internetbaidu.com/mobileTXW_3.0/fish/fish_try_now.png');
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
